/* Container styling */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f9f9f9;
}

/* Form styling */
.form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

/* Title styling */
.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333333;
  text-align: center;
}

/* Label styling */
.label {
  font-size: 0.875rem;
  color: #555555;
  margin-bottom: 0.5rem;
  display: block;
}

/* Input styling */
.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #333333;
}

.input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Checkbox container styling */
.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.checkbox {
  margin-right: 0.5rem;
}

.checkboxLabel {
  font-size: 0.875rem;
  color: #555555;
}

.checkboxLabel a {
  color: #007bff;
  text-decoration: none;
}

.checkboxLabel a:hover {
  text-decoration: underline;
}

/* Button styling */
.button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #0056b3;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
