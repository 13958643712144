.contentContainer{
    
    width: 50vw;
    margin:auto;
    background-color: rgb(219, 231, 231);
    min-height: 100vh;
    border-radius: 10px;
    padding-left: 2%;
    margin-top: 20px;   /* Adds space above the container */
    margin-bottom: 20px; /* Adds space below the container */
}
.centeredTitle {
    text-align: center;
    color: #3DC2CD;
    margin: auto;
    font-size: 2rem;
  }
  .container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f7fa;
    padding: 2rem 1rem;
  }
  
  .content {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .lastUpdated {
    color: #666;
    text-align: center;
    margin-bottom: 2rem;
    font-style: italic;
  }
  
  .introduction {
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .section {
    margin-bottom: 2.5rem;
  }
  
  .section h2 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e1e8ed;
    padding-bottom: 0.5rem;
  }
  
  .section h3 {
    color: #34495e;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  
  .checkList {
    list-style: none;
    padding-left: 1.5rem;
  }
  
  .checkList li {
    position: relative;
    margin-bottom: 0.8rem;
    line-height: 1.5;
  }
  
  .checkList li::before {
    content: "✅";
    position: absolute;
    left: -1.5rem;
  }
  
  .highlight {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    border-left: 4px solid #4a90e2;
    margin: 1rem 0;
    font-weight: 500;
  }
  
  .contact {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem;
  }
  
  .footer {
    margin-top: 3rem;
    text-align: center;
    padding-top: 2rem;
    border-top: 2px solid #e1e8ed;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .content {
      padding: 1.5rem;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .section h2 {
      font-size: 1.3rem;
    }
  
    .section h3 {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 0.5rem;
    }
  
    .content {
      padding: 1rem;
      border-radius: 8px;
    }
  
    .title {
      font-size: 1.3rem;
    }
  
    .checkList li {
      font-size: 0.9rem;
    }
  
    .highlight {
      padding: 0.8rem;
    }
  }