@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap");

* {
  font-family: "Baloo 2", serif;
}
h5 {
  margin: 0;
}
img {
  object-fit: contain;
}
/* For Laptop  */
@media (max-width: 1440px) {
  /* Promo Banner */
  .promo-banner-large-text {
    margin: 0px;
    font-size: 23px !important;
  }
  .promo-banner-small-text {
    margin: 0px;
    font-size: 15px !important;
  }
  .people-image {
    height: 120px !important;
    width: 140px !important;
  }
  .start-talking-image {
    height: 40px !important;
    width: 180px !important;
  }

  /* Podcast Card */
  .podcast-card-container {
    width: 400px !important;
  }
  .podcast-card-starImage {
    height: 40px !important;
    width: 40px !important;
    margin-right: 8px !important;
  }
  .podcast-card-grpPlayPauseImage {
    height: 50px !important;
    width: 50px !important;
  }

  /* Podcast Tile */
  .podcast-tile-container {
    width: 80% !important;
  }
  .podcast-tile-item {
    width: 300px !important;
  }
  .podcast-tile-playButton {
    height: 40px !important;
    width: 40px !important;
  }
  .podcast-tile-starButton {
    height: 35px !important;
    width: 35px !important;
  }
  .podcast-tile-logo {
    height: 55px !important;
    width: 55px !important;
  }

  /* Quote Card */
  .QuoteCard-cardImage {
    height: 150px !important;
    width: 150px !important;
  }
  /* Profile Page */
  .profile-right-mini-container-text {
    font-size: 15px !important;
  }
  .profile-person-details-left-text {
    font-size: 15px !important;
  }
  .profile-referral-image1 {
    height: 45px !important;
    width: 130px !important;
  }
  .profile-image1 {
    height: 130px !important;
    width: 130px !important;
  }
  .profile-person-name {
    font-size: 20px !important;
    margin-bottom: 30px !important;
  }
}

/* For Phone */
@media (max-width: 425px) {
  /* Header */
  .header-container {
    height: 70px !important;
  }
  .all-tabs {
    width: 90% !important;
  }
  .tab-container {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .logo-container {
    width: 10% !important;
  }
  .notification-logo-container {
    width: 10% !important;
  }
  .image-logo {
    height: 40px !important;
    width: 30px !important;
  }
  .tab-image {
    height: 20px !important;
    width: 20px !important;
  }
  .tab-image-podcast {
    height: 23px !important;
    width: 23px !important;
  }
  .header-tab-name {
    font-size: 12px;
  }
  .header-notification-logo {
    height: 25px !important;
    width: 25px !important;
  }
  .header-button {
    height: 40px !important;
  }
  .header-button-text {
    padding: 0px !important;
    font-size: 10px !important;
  }
  /* Footer  */
  .footer-container {
    height: 30px !important;
  }
  .footer-left-text {
    font-size: 8px !important;
  }
  .footer-button {
    padding: 0px !important;
    font-size: 10px !important;
  }
  /* Emotion List */
  .emotion-list-container {
    width: 100% !important;
  }
  .emotion-image {
    height: 50px !important;
    width: 50px !important;
  }
  .emotion-text {
    font-size: 10px !important;
  }
  .emotion-item {
    min-width: 60px !important;
    margin-right: 20px !important;
  }
  /* Promo Card */
  .promo-banner-container {
    width: 80% !important;
    height: 150px !important;
  }
  .start-talking-image {
    height: 25px !important;
    width: 100px !important;
  }
  .promo-banner-large-text {
    font-size: 15px !important;
  }
  .promo-banner-small-text {
    font-size: 10px !important;
  }
  .people-image {
    height: 100px !important;
    width: 120px !important;
  }
  .start-speaking-image {
    height: 270px !important;
    width: 270px !important;
  }
  /* FAQ Tile */
  .questions-logo {
    width: 30px !important;
    height: 30px !important;
  }
  .faq-title {
    font-size: 20px !important;
  }
  .faq-question {
    font-size: 12px !important;
  }
  .faq-arrow {
    width: 15px !important;
    height: 15px !important;
  }
  .faq-logo {
    width: 20px !important;
    height: 20px !important;
  }
  .faq-description {
    font-size: 10px !important;
  }
  /* Podcast Page */
  .premium-page-header {
    height: 100px !important;
  }
  .premium-page-header-title {
    font-size: 25px !important;
  }
  .premium-page-header-message {
    font-size: 18px !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    text-align: center;
  }
  /* Quotes Page */
  .QuotesPage-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10px !important;
    width: 100% !important;
  }
  .QuoteCard-cardContainer {
    margin: 5px !important;
    padding: 10px !important;
  }
  .QuoteCard-cardImage {
    width: 120px !important;
    height: 120px !important;
  }
  .QuoteCard-cardTitle {
    margin-top: 10px !important;
    font-size: 12px !important;
  }
  /* Profile Page */
  .profile-page-image {
    height: 120px !important;
    width: 120px !important;
    margin-top: 20px !important;
  }
  .premium-image {
    height: 20px !important;
    width: 20px !important;
    top: 40px !important;
    left: 15px !important;
  }
  .profile-card {
    width: 80% !important;
  }
  .profile-addCredit-button {
    width: 60% !important;
    height: 40px !important;
  }
  .profile-wallet-image {
    height: 20px !important;
    width: 20px !important;
  }
  .profile-name {
    font-size: 20px !important;
    margin-left: 10px !important;
  }
  .profile-card-elements {
    width: 60% !important;
    height: 60% !important;
  }
  .profile-card-element-text {
    font-size: 15px !important;
  }
  .profile-logout-image {
    height: 30px !important;
    width: 30px !important;
  }
  .profile-gpn-image {
    height: 20px !important;
    width: 20px !important;
    margin-right: 0px !important;
  }
  /* Auth Page */
  .auth-upperContainer {
    height: 350px !important;
  }
  .auth-imageLogo {
    width: 150px !important;
    height: 150px !important;
    margin-top: 10px !important;
  }
  .auth-title {
    font-size: 25px !important;
  }
  .auth-title1 {
    font-size: 15px !important;
  }
  /* Sign In */
  .sign-in-container {
    width: 100% !important;
  }
  .input-field {
    width: 70% !important;
  }
  /* Sign Up */
  .sign-up-container {
    width: 100% !important;
  }
  /* Podcast Card */
  .podcast-card-container {
    height: 30vh;
    width: 350px !important;
  }
  /* Premium Card */
  .premium-card-container {
    width: 350px !important;
  }
  .premium-card-text1 {
    font-size: 12px !important;
  }
  .premium-card-text2 {
    font-size: 12px !important;
  }
  /* ProfilePage */
  .profile-right-mini-container-text {
    font-size: 10px !important;
  }
  .profile-right-mini-container-row {
    flex-direction: column !important;
  }
  .profile-referral-image1 {
    height: 40px !important;
    width: 100px !important;
  }
  .profile-image1 {
    height: 180px;
    width: 180px;
}
}

/* styles/Home.css */
.page-container .start-speaking-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.start-speaking-image {
  width: 50vh;
  height: 50vh;
}

.faq-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.questions-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.faq-title {
  color: #00b2c0;
  font-size: 2rem;
}

.faq-list-container-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Space between tiles */
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 50%;
}

/* SignIn Component Styles */

.submit-button {
  width: 50%;
  background-color: #00b2c0;
  height: 60px;
  margin-top: 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.error-message {
  font-size: 15px;
  color: red;
}

.forgot-password {
  font-size: 15px;
  color: red;
  font-weight: 500;
  background-color: white;
  border: none;
}

.submit-text {
  color: white;
}

/* FaqTile Styles */
.faq-tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  border: 1px solid #51cbd5;
  padding: 15px;
  width: 90%; /* Adjust width to fit the column */
  margin: 0 auto;
}

.faq-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.faq-logo {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.faq-question {
  flex: 1;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}

.faq-arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.3s;
}

.faq-arrow.open {
  transform: rotate(180deg);
}

.faq-description {
  margin-top: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #555;
  width: 100%;
}

/* EmotionList Component Styles */
.emotion-list-container {
  overflow: hidden;
  white-space: nowrap;
  padding: 20px;
  gap: 30px;
  width: 70%;
}

.emotion-item {
  text-align: center;
  flex: none; /* Ensure items do not shrink */
  min-width: 100px;
  margin-right: 40px;
}

.emotion-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.emotion-text {
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}

/* circular-wave-animation styles */
.circular-wave-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  width: 100%;
}

.wave-container {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 30px solid; /* Reduced border size for closer waves */
  animation: waveAnimation 2s infinite;
}

.wave1 {
  border-color: #51cbd5;
  animation-delay: 0s;
}

.wave2 {
  border-color: #a1e3e9;
  animation-delay: 0.4s;
}

.wave3 {
  border-color: #e5f8f9;
  animation-delay: 0.8s;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
}

@keyframes waveAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8); /* Reduced scale for less spread */
    opacity: 0;
  }
}

/* Header styles */
.header-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  background-color: #3dc2cd;
  flex-direction: row;
}

.logo-container {
  display: flex;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.notification-logo-container {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
}

.image-logo {
  width: 120px;
  height: 100px;
  object-fit: contain;
}

.header-notification-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.all-tabs {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.tab-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.header-tab-name {
  margin: 0px;
}

.tab-image {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  object-fit: contain;
}
.tab-image-podcast {
  height: 35px;
  width: 35px;
  margin-bottom: 8px;
}

.tab-image-video {
  height: 25px;
  width: 40px;
  margin-bottom: 10px;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
}

.header-button {
  background-color: white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 10%;
}

.notification-logo-container {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
}

.header-button-logout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  background-color: #3dc2cd;
  width: 10%;
}

.header-button-logout-text {
  font-size: 15px;
  color: black;
  margin: 0;
  margin-top: 5px;
}

.header-button-text {
  color: black;
  padding: 20px;
}

.login-link {
  display: flex;
  width: 30%;
  justify-content: flex-start;
}

/* Promo banner styles */

.promo-banner-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.promo-banner-container {
  width: 50%;
  height: 30vh;
  background-color: #e8ebed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 15px;
}

.start-talking-image {
  height: 40px;
  width: 200px;
  margin-top: 10px;
}

.promo-banner-large-text {
  margin: 0px;
  font-size: 30px;
  margin-bottom: 10px;
}

.promo-banner-small-text {
  margin: 0px;
  font-size: 20px;
  margin-bottom: 10px;
}

.inner-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.people-image {
  height: 150px;
  width: 200px;
}

/* AuthPage Component Styles */
.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.auth-upperContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00b2c0;
  height: 450px;
}

.auth-buttonContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  border: none;
  /* background-color: #333; */
}

.auth-button {
  border: none;
  background-color: white;
  width: 50%;
}

.auth-activeButton {
  border: none;
  background-color: #128089;
  width: 50%;
}

.auth-buttonText {
  color: black;
  font-weight: 500;
  font-size: x-large;
}

.auth-buttonTextActive {
  color: white;
  font-weight: 500;
  font-size: x-large;
}

.auth-imageLogoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-imageLogo {
  width: 200px;
  height: 200px;
  margin-top: 30px;
}

.auth-titleContainer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.auth-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin: 0;
  margin-bottom: 5px;
}

.auth-title1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: white;
  margin: 0;
}

.auth-lowerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  align-items: center;
  height: 100%;
}

/* sign up page styles*/

.sign-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 60%;
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 60%;
  justify-content: center;
}

.input-field {
  width: 50%;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  border: 2px solid #00b2c0;
  padding: 0 10px;
  font-size: 1rem;
}

.submit-text {
  color: white;
  font-size: 1.2rem;
}

/* QuotesPage Styles */
.QuotesPage-pageContainer {
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QuotesPage-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 70%;
}

/* QuoteCard Styles */
.QuoteCard-cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 15px;
  background-color: #f0f5f8;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.QuoteCard-cardContainer:hover {
  transform: scale(1.05);
}

.QuoteCard-cardImage {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.QuoteCard-cardTitle {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* QuoteDetailsPage Styles */
.QuoteDetailsPage-pageContainer {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.QuoteDetailsPage-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  background-color: #00b2c0;
  width: 100%;
  height: 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
}

.QuoteDetailsPage-quotesContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
  justify-content: center;
  align-items: flex-start;
}

.QuoteDetailsPage-quoteItem {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  height: 150px;
}

.QuoteDetailsPage-quoteImage {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.QuoteDetailsPage-quoteText {
  font-size: 30px;
  color: #333;
}

/* RatingPage Styles */

.rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rating-like-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  flex-direction: column;
}
.rating-text {
  font-size: xx-large;
}
.rating-button-container {
  display: flex;
  width: 30%;
  height: 200px;
  align-items: center;
  justify-content: space-around;
}
.rating-button {
  background-color: #00b2c0;
  width: 40%;
  border: none;
  border-radius: 5px;
}
.rating-button-text {
  color: white;
  margin: 0;
  padding: 10px;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.gender-selection {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
}

.gender-selection label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.gender-selection input {
  margin-right: 5px;
}

/* Global Styles for TalkingPage */

.talking-header-container {
  background: linear-gradient(to bottom, #3ac3ce, #66d0d9, #a4e4e9, #d7f3f5);
  padding: 20px;
  height: 80px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.talking-header-text {
  margin: 0;
  color: black;
  font-size: 24px;
  font-weight: 500;
  width: 50%;
  text-align: center;
}

.talking-page-timer-text {
  text-align: center;
}

.talking-change-quotes-button-container {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.talking-change-quotes-button {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #42c6d0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.talking-wave-component-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.talking-wave-message {
  background-color: #e5f8f9;
  border: 1px solid #51cbd5;
  border-radius: 10px;
  padding: 15px;
}

.talking-not-wave-component-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 50px;
}

.talking-profile-image {
  height: 150px;
  width: 150px;
  margin-right: 10px;
}

.talking-profile-image-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.talking-mic-image {
  height: 150px;
  width: 150px;
  margin-right: 10px;
}

.talking-footer-container {
  width: 100%;
  height: 100px;
  background-color: #00b2c0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.talking-footer-button {
  border: none;
  background: none;
}

.talking-footer-icon {
  height: 70px;
  width: 70px;
  margin-left: 20px;
  margin-right: 20px;
}

.talking-search-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.talking-reload-icon {
  height: 15px;
  width: 15px;
  margin-left: 10px;
}

.talking-finding-friend-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

/* Profile page styles */

/* .profile-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #00b2c0;
  height: 100vh;

} */

.profile-page-image {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.profile-name {
  font-size: x-large;
  color: white;
  margin: 0;
  text-align: center;
}

.profile-main-container {
  display: flex;
  flex-direction: column;
}

.profile-GPN-number {
  font-size: small;
  color: white;
  margin: 0;
}

.profile-gpn-image {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.profile-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.premium-image {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50px;
  left: 15px;
}

.profile-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 40%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-direction: column;
}

.profile-addCredit-button {
  display: flex;
  width: 40%;
  background-color: #3dc2cd;
  height: 60px;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.profile-wallet-image {
  height: 30px;
  width: 30px;
}
.profile-card-image {
  height: 20px;
  width: 20px;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 5px;
}

.profile-logout-image {
  height: 40px;
  width: 40px;
}
.profile-header {
  align-items: center;
  justify-content: space-between;
  width: 40%;
  display: flex;
}
.profile-card-elements {
  width: 60%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60%;
}

.profile-referral-container {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-referral-image {
  height: 100px;
  width: 200px;
  object-fit: contain;
}

.profile-log-out {
  background: none;
  border: none;
}

.profile-card-element-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.profile-card-element-text {
  margin: 0;
  font-size: 20px;
  height: 100%;
}

.profile-card-element-text-right {
  margin: 0;
  font-size: 20px;
  height: 100%;
  font-weight: 400;
  text-align: center;
}

.profile-card-elements-container {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
}

/* New Profile styles */

.profile-container {
  display: flex;
  height: 100vh;
  background-color: #00b2c0;
  justify-content: center;
}

.profile-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  flex-direction: column;
  padding: 50px;
}

.profile-image-container {
}
.profile-image1 {
  height: 180px;
  width: 180px;
}
.profile-person-name {
  font-weight: bold;
  color: white;
  font-size: 30px;
  margin-bottom: 50px;
}
.profile-person-details-left-text {
  font-weight: bold;
  color: white;
  font-size: 30px;
}
.profile-person-details-container {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
}
.profile-referral-image1 {
  height: 50px;
  width: 150px;
  object-fit: contain;
}
.profile-referral-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.profile-left-bottom-img-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}
.profile-right-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  flex-direction: column;
}
.profile-right-mini-container {
  display: flex;
  background-color: white;
  width: 40%;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 20%; 
  flex-direction: column;
}
/* make height 40% for 4 cards */
.profile-right-mini-container-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
.profile-right-mini-container-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
}
.profile-right-mini-container-text {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

/* Premium styles */

.premium-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.premium-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 120px;
  background: linear-gradient(to bottom, #03b4c1, #4cc9d3, #c8eff1);
}

.premium-page-header-title {
  font-size: 30px;
  margin: 0;
  color: white;
}

.premium-page-header-message {
  font-size: 20px;
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
}

/* PremiumRate Component Styling */

.premium-rate-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.premium-rate-main-container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.premium-rate-main-container3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.premium-rate-paymentlogo-container {
  width: 100%;
  height: 10vh;
  align-items: center;
  justify-items: center;
  display: flex;
}
.premium-rate-cardlogo-container {
  width: 100%;
  align-items: center;
  justify-items: center;
  display: flex;
}
.premium-rate-paymentlogo {
  object-fit: contain;
  height: 80%;
  width: 80%;
}
.premium-rate-cardlogo {
  height: 80%;
  width: 80%;
  object-fit: contain;
}
.premium-rate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 60vh;
}
.premium-rate-main-container2 {
  display: flex;
  flex-direction: row;
}
.premium-rate-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.premium-rate-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.premium-audio-image {
  height: 50px;
  object-fit: contain;
}

.premium-rate-counter-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.premium-rate-counter-button {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #00b2bf;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.premium-rate-counter-button:hover {
  background-color: #008a94;
}

.premium-rate-hours-text {
  font-size: 18px;
  font-weight: bold;
}

.premium-rate-buy-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.premium-rate-buy-button:hover {
  background-color: #3d8f41;
}

/* Podcast Card Container */
.podcast-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.podcast-card-container {
  display: flex;
  height: 30vh;
  width: 500px;
  background-color: #00b2c0;
  margin-top: 50px;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.podcast-card-image-container {
  width: 70%;
  height: 70%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.podcast-card-grpImage {
  height: 80%;
  width: 70%;
}

.podcast-card-grpPlayPauseImage {
  height: 60px;
  width: 60px;
  position: absolute;
}

.podcast-card-footer {
  width: 100%;
  background-color: #99e0e6;
  height: 30%;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.podcast-card-footer-leftContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.podcast-card-footer-leftContainer-upperText {
  margin: 0;
  margin-bottom: 5px;
  font-size: 20px;
}

.podcast-card-footer-leftContainer-lowerText {
  margin: 0;
  font-size: 15px;
}

.podcast-card-starImage {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

/* PodcastTile Styles */
.podcast-tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 50%;
  margin-top: 20px;
}

/* Each tile item */
.podcast-tile-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px; /* Two tiles per row */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
}

/* Logo on the tile */
.podcast-tile-logo {
  height: 70px;
  width: 70px;
}

/* Title and duration in the middle */
.podcast-tile-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin: 10px;

  height: 100%;
}

.podcast-tile-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.podcast-tile-duration {
  font-size: 14px;
  color: #555;
  margin: 0;
}

/* Buttons at the bottom */
.podcast-tile-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 30%;
  align-items: center;
  justify-content: center;
}

.podcast-tile-playButton {
  height: 60px;
  width: 60px;
}

.podcast-tile-starButton {
  height: 50px;
  width: 50px;
}
.podcast-tile-button {
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.podcast-tile-button:nth-child(1) {
  background-color: #ff9800;
  color: white;
}

.podcast-tile-button:nth-child(2) {
  background-color: #4caf50;
  color: white;
}

.podcast-tile-button:hover:nth-child(1) {
  background-color: #e68900;
}

.podcast-tile-button:hover:nth-child(2) {
  background-color: #3d8f41;
}

.podcast-details-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.podcast-detail-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 200px;
  background-color: #00b2c0;
  margin-top: 50px;
  flex-direction: column;
}

.podcast-detail-card-title {
  color: white;
  font-size: 15px;
}

/* FriendRequestPage styling */
/* Container styling */
.friend-request-page-container {
  padding: 20px;
  background-color: #3dc2cd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title */
.friend-request-page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Friend request list styling */
.friend-request-list {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Friend request item styling */
.friend-request-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.friend-request-item:last-child {
  border-bottom: none;
}

/* Profile picture */
.friend-request-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #00b2bf;
}

/* Sender name */
.friend-request-sender-name {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Action buttons */
.friend-request-actions {
  display: flex;
  gap: 10px;
}

.friend-request-accept-button,
.friend-request-reject-button {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.friend-request-accept-button {
  background-color: #28a745;
  color: white;
}

.friend-request-accept-button:hover {
  background-color: #218838;
}

.friend-request-reject-button {
  background-color: #dc3545;
  color: white;
}

.friend-request-reject-button:hover {
  background-color: #c82333;
}

/* Friends page styling */
/* Container styling */
.friends-page-container {
  background-color: #00b2c0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Title styling */
.friends-page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Friends list container */
.friends-list {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Individual friend item styling */
.friend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}

.friend-item:last-child {
  border-bottom: none;
}

/* Profile picture styling */
.friend-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #00b2bf;
}

/* Friend name styling */
.friend-name {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Call button styling */
.friend-call-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s ease-in-out;
}

.friend-call-button:hover {
  transform: scale(1.1);
}

/* Call button icon */
.friend-call-icon {
  width: 40px;
  height: 40px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Footer Container */
.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f5f8;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-family: Arial, sans-serif;
  height: 60px;
}

.footer-left-text {
  color: #333;
}

.footer-right-buttons {
  display: flex;
  align-items: center;
}

.footer-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.footer-separator {
  margin: 0 5px;
  color: #333;
}

/* Global styles for OtpPage */
.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.otp-title {
  font-size: 35px;
  color: black;
}

.phone-input-container,
.otp-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.phone-input,
.otp-input {
  width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  height: 30px;
}

.phone-input:focus,
.otp-input:focus {
  outline: none;
  border-color: #007bff;
}

.send-otp-button,
.verify-otp-button {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #00b2c0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-otp-button-text {
  font-size: 20px;
  margin: 0px;
}

.send-otp-button:hover,
.verify-otp-button:hover {
  background-color: #0b868f;
}

/* Terms and Conditions Page Styles */
.terms-container {
  width: 80%;
  max-width: 900px;
  margin: 40px auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-header-terms h1 {
  font-size: 32px;
  color: #00b2bf;
  text-align: center;
  margin-bottom: 20px;
}

.terms-content-terms {
  font-size: 16px;
  line-height: 1.7;
  color: #333;
}

.terms-content-terms p {
  margin-bottom: 20px;
}

.terms-content-terms ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.terms-content-terms h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.terms-content-terms li {
  margin-bottom: 10px;
}

/* Privacy Policy Page Styles */
.privacy-container {
  width: 80%;
  max-width: 900px;
  margin: 40px auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.privacy-header h1 {
  font-size: 32px;
  color: #00b2bf;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-content {
  font-size: 16px;
  line-height: 1.7;
  color: #333;
}

.privacy-content p {
  margin-bottom: 20px;
}

.privacy-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.privacy-content h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.privacy-content li {
  margin-bottom: 10px;
}

/* Video Page styling */
.video-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Arial", sans-serif;
}

.video-card {
  text-align: center;
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: video-fadeIn 1s ease-in-out;
}

.video-title {
  font-size: 2.5rem;
  color: #6a11cb;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.video-subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 20px;
}

.video-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.video-animation-circle {
  width: 15px;
  height: 15px;
  background-color: #2575fc;
  border-radius: 50%;
  animation: video-bounce 1.5s infinite ease-in-out;
}

.video-animation-circle:nth-child(2) {
  animation-delay: 0.2s;
}

.video-animation-circle:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes video-fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes video-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Video Call Page */
.video-call-remoteUser {
  height: 90vh;
  width: 50%;
}

.video-call-container {
  height: 100vh;
}


/* PsychologistSignupPage */
.PsychologistSignupPage-container{
  align-items:center;
  justify-content: center;display: flex;
  flex-direction: column;
}