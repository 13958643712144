.container {
    max-width: 400px;
    margin: 50px auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }

  .container_parent{
    display: flex;
    align-items:center;
    justify-content:center;
    height: 80vh;
}
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .deleteButton {
    background-color: #e63946;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: #d62839;
  }
  